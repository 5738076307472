<template>
  <div class="reward-postpone-list">
    <div class="w-full flex items-center " style="margin-bottom: 24px; gap: 12px">
      <PageTitle
        class="flex-1"
        style="margin-bottom: 0"
        title="獎勵展延列表"
      >
        <template #btn1>
          <el-button
            type="primary"
            :disabled="disabledAddBtn"
            @click="onCreate"
          >
            新增
          </el-button>
        </template>
      </PageTitle>
      <TipInfo>
        <div>可於每年的 1/1 - 1/31 間，選擇將前一年度尚未過期獎勵展延至當年度獎勵內。</div>
      </TipInfo>
    </div>

    <FiltersContainer>
      <el-select v-model="search.status" clearable @change="refresh" @clear="refresh">
        <el-option
          v-for="status in rewardPostponeTaskStatusConfig"
          :key="status.value"
          :label="status.label"
          :value="status.value"
        />
      </el-select>
    </FiltersContainer>

    <RewardPostponeTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import TipInfo from '@/components/TipInfo.vue'
import RewardPostponeTable from './components/RewardPostponeTable.vue'
import { rewardPostponeTaskStatusConfig } from '@/config/rewardPostpone'
import { GetMemberBatchTask, GetMemberBatchTaskCount } from '@/api/memberBatchTask'
import dayjs from 'dayjs'
import { includes } from 'lodash'

export default defineComponent({
  name: 'RewardPostponeList',
  components: {
    PageTitle,
    RewardPostponeTable,
    TipInfo,
  },
  setup (props) {
    const router = useRouter()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    const search = reactive({
      status: null,
    })

    const onCreate = () => router.push({ name: 'RewardPostponeEdit' })

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        status: search.status || undefined,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        fetchData(GetMemberBatchTask, payload),
        fetchDataCount(GetMemberBatchTaskCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    const disabledAddBtn = computed(() => {
      const activeStart = dayjs().month(0).date(1).startOf('day')
      const activeEnd = dayjs().month(0).date(31).endOf('day')

      if (dayjs().isBefore(activeStart, 'm') || dayjs().isAfter(activeEnd, 'm')) return true
      return false
    })

    onMounted(() => {
      refresh()
    })
    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      onCreate,
      refresh,
      search,
      rewardPostponeTaskStatusConfig,
      disabledAddBtn,
    }
  },
})
</script>
