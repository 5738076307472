export const rewardPostponeTaskStatusConfig = {
  pending: { label: '待處理', value: 'pending', tagType: 'info' },
  processing: { label: '處理中', value: 'processing', tagType: 'warning' },
  completed: { label: '已完成', value: 'completed', tagType: 'action' },
  failed: { label: '失敗', value: 'failed', tagType: 'error' },
}

export const rewardPostponeTaskTypesConfig = {
  pointYearlyExtension: { label: '點數年度展延', value: 'pointYearlyExtension' },
  cashbackYearlyExtension: { label: '回饋金年度展延', value: 'cashbackYearlyExtension' },
}

export const rewardPostponeConditionTypesConfig = {
  all: { label: '所有會員', value: 'all' },
  tag: { label: '指定標籤會員', value: 'tag' },
  specific: { label: '指定會員', value: 'specific' },
}
